.wpwl-form-card {
  border-radius: 12px;
  border: 1px solid var(--Colours-Primary-Memories-950, #1a2637);
  box-shadow: none;
  background-color: transparent !important;
  padding: 0;
}

.wpwl-group.wpwl-group-brand {
  position: relative;
  background: var(--Colours-Primary-Memories-50, #f4f7fb);
  border-radius: 12px 12px 0 0;
  padding: 12px 12px 12px 12px;
}
.wpwl-label.wpwl-label-brand {
  padding-top: 17px;
}
.wpwl-group-cardNumber,
.wpwl-group-cardHolder,
.wpwl-group-birthDate {
  padding-right: 12px;
  width: 66.66666667%;
  padding-left: 12px;
}
.wpwl-group {
  padding: 0 12px;
}
.wpwl-group-expiry,
.wpwl-group-brandLogo,
.wpwl-group-cvv {
  width: 33.33333333%;
  padding-right: 12px;
}
.wpwl-control {
  border-radius: var(--Input-Fields-Borders-Radius-Border-Radius, 7.999px);
  border: 1px solid
    var(--Input-Fields-Borders-Colours-Populated-Border, #c9c9c9);
  background: var(--Colours-Greyscale-1000, #fff);
  height: 47.994px;
  padding: 0 11.999px;
  color: var(--Input-Fields-Text-Label-Text, #757575);
  font-size: 13.998px;
  font-weight: 400;
}
.wpwl-control::placeholder {
  color: var(--Input-Fields-Text-Label-Text, #757575);
  font-size: 13.998px;
  font-weight: 400;
}
.wpwl-control:focus {
  border-color: #4b9d72;
  outline: 0;
}
.wpwl-button-pay {
  border-radius: 40.328px;
  background: var(--Buttons-Colours-Secondary-Secondary-Default, #d8662a);
  color: var(--Text-Text-White, #fff);
  font-size: 17.998px;
  font-style: normal;
  padding: 13px;
  font-weight: 500;
  width: 100%;
  border: none;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.wpwl-button-pay:hover {
  border-radius: var(--Buttons-Border-radius-Default-Border-Radius, 40px);
  background: var(--Buttons-Colours-Secondary-Secondary-Hover, #de7831);
}
.wpwl-label {
  color: var(--Input-Fields-Text-Label-Text, #757575);
  font-size: 13.998px;
  font-weight: 400;
  padding-bottom: 3px;
}

.wpwl-control-brand {
  display: none;
}
.wpwl-label-brand {
  display: none;
}
